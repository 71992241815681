import {
	DuplicateIcon,
	EditRowIcon,
	ModalCloseIcon,
	PreviewRowIcon,
} from "@src/assets/SvgComponents";
import {useForm} from "@src/utils/useForm";
import {Modal} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Storage} from "@src/utils/local-storage";
import {useTranslation} from "react-i18next";
import ButtonComponent from "../button";
import Loader from "../Loader";
import RangePicker from "../Range-Picker";
import CustomSelect from "../Select";
import {renderFormInputs} from "./helper";
import CardInfo from "../styles/common/energy";
import PreviewInput from "./PreviewInput";

const Form = (props) => {
	const {
		loading,
		formData,
		open,
		handleClose,
		width = 500,
		inputsWrapperClassName = "",
		canSave = true,
		CustomTitle = null,
		duplicate = false,
		onDuplicateClick,
		innerModal = null,
		formLoading = false,
		onMount,
		modal = "",
		id = null,
		preview = false,
		onViewToggle = null,
		previewInputs,
	} = props;
	const {inputs, title} = formData;
	const {formik, pending} = useForm(formData, open, onMount);
	const theme = Storage.get("theme");
	const [fieldInfo, setFieldInfo] = useState({});
	const [currentTrainer, setCurrentTrainer] = useState(null);
	const {handleSubmit, resetForm} = formik;
	const {t} = useTranslation();
	// functions for inner modal used for user dates at sales organization to line 64
	const [datesModal, setDatesModal] = useState({
		open: false,
		obj: null,
		type: "dates",
	});
	const handleDates = (field, value) => {
		if (formik?.values?.[innerModal?.fieldToSetValue]) {
			const updatedItem = formik?.values?.[innerModal?.fieldToSetValue]?.map((item) => {
				if (item?.id === datesModal?.obj?.id) {
					const obj = {
						...item,
						[field]: !item?.[field] && value,
						endDate: field === "startDate" ? null : value,
						trainer: !!(datesModal?.type === "trainer" && value !== null),
					};
					return obj;
				}
				return item;
			});
			formik.setFieldValue(innerModal?.fieldToSetValue, updatedItem);
		} else {
			formik.setFieldValue(innerModal?.fieldToSetValue, [
				...formik?.values?.[innerModal?.fieldToSetValue],
			]);
		}
	};
	const setInternValue = (option) => {
		const findTrainer = formik?.values?.[innerModal?.fieldToSetValue].find(
			(trainer) => trainer.id === option.value,
		);
		setCurrentTrainer(findTrainer);
		if (formik?.values?.[innerModal?.fieldToSetValue]) {
			const updatedItem = formik?.values?.[innerModal?.fieldToSetValue]?.map((item) => {
				if (item?.id === datesModal?.obj?.id) {
					const obj = {
						...item,
						internship: true,
						trainerId: option?.value,
					};
					return obj;
				}
				return item;
			});
			formik.setFieldValue(innerModal?.fieldToSetValue, updatedItem);
		} else {
			formik.setFieldValue(innerModal?.fieldToSetValue, [
				...formik?.values?.[innerModal?.fieldToSetValue],
			]);
		}
	};
	const getInnerModalValue = (type, obj) => {
		const find = formik?.values?.[innerModal?.fieldToSetValue]?.find(
			(item) => item?.id === obj?.id,
		);
		if (find?.[type]) {
			return find?.[type];
		}
		return null;
	};
	const disableTrainerDates = (current, type) => {
		if (current && datesModal.type === "intern") {
			const trainers = formik?.values?.teamMembers.filter((item) => item?.trainer);
			const intern = formik?.values?.teamMembers.find(
				(item) => item?.id === datesModal.obj.id,
			);
			const findTrainer = trainers.find((item) => item?.id === intern?.trainerId);
			return type === "start"
				? moment(findTrainer?.startDate).valueOf() > moment(current).valueOf() ||
						moment(findTrainer?.endDate).valueOf() < moment(current).valueOf()
				: moment(findTrainer?.endDate).valueOf() < moment(current).valueOf();
		}
		return false;
	};

	const disabledDate = (current) =>
		(current &&
			((formik?.values?.[innerModal.disableDatesFields[0]] &&
				moment(formik?.values?.[innerModal.disableDatesFields[0]]).valueOf() >
					moment(current).valueOf()) ||
				(formik?.values?.[innerModal.disableDatesFields[1]] &&
					moment(formik?.values?.[innerModal.disableDatesFields[1]]).valueOf() <
						moment(current).valueOf()))) ||
		disableTrainerDates(current, "start");

	const disabledEndDate = (current) => {
		if (
			getInnerModalValue(innerModal?.fields?.[0], datesModal?.obj) === null ||
			moment(getInnerModalValue(innerModal?.fields?.[0], datesModal?.obj)).valueOf() >
				moment(current).valueOf()
		) {
			return true;
		}
		return (
			current &&
			((formik?.values?.[innerModal.disableDatesFields[0]] &&
				moment(formik?.values?.[innerModal.disableDatesFields[0]]).valueOf() >
					moment(current).valueOf()) ||
				(formik?.values?.[innerModal.disableDatesFields[1]] &&
					moment(formik?.values?.[innerModal.disableDatesFields[1]]).valueOf() <
						moment(current).valueOf()) ||
				disableTrainerDates(current, "end"))
		);
	};
	const renderInnerModal = () => (
		<>
			<div className="modal__component-header">
				<span className="title">{datesModal?.obj?.name}</span>
			</div>
			{datesModal?.type === "intern" && (
				<CustomSelect
					options={
						formik?.values?.[innerModal?.fieldToSetValue]
							.filter((item) => item.trainer)
							.map((i) => ({
								label: i.name,
								value: i.id,
							})) || []
					}
					onSelect={(value, option) => setInternValue(option)}
					value={
						formik?.values?.[innerModal?.fieldToSetValue].find(
							(i) => i.id === datesModal?.obj?.id,
						).trainerId
					}
					showSearch
					allowClear={false}
					placeholder={innerModal.dropDownPlaceholder}
				/>
			)}
			<RangePicker
				setFieldValue={handleDates}
				picker={innerModal?.picker}
				customDisabledStartDate={disabledDate}
				customDisabledEndDate={disabledEndDate}
				startDateProps={{
					field: innerModal?.fields?.[0],
					value: getInnerModalValue(innerModal?.fields?.[0], datesModal?.obj),
					disabled:
						datesModal.type === "intern" &&
						!formik?.values?.[innerModal?.fieldToSetValue].find(
							(i) => i.id === datesModal?.obj?.id,
						)?.trainerId,
				}}
				endDateProps={{
					field: innerModal?.fields?.[1],
					value: getInnerModalValue(innerModal?.fields?.[1], datesModal?.obj),
					disabled:
						getInnerModalValue(innerModal?.fields?.[0], datesModal?.obj) === null ||
						(datesModal.type === "intern" && !currentTrainer?.endDate),
				}}
				type={innerModal?.type}
				innerModal
			/>
		</>
	);

	useEffect(() => {
		if (open) {
			resetForm();
		}
	}, [open]);

	const toggleInfoVisibility = (item) => {
		setFieldInfo((prev) => {
			if (item.field in prev) {
				return {};
			}
			return {
				[item.field]: item.additionalInfo,
			};
		});
	};
	if (!open) return null;
	return (
		<Modal
			open={open}
			className="modal__component"
			closable={false}
			footer={null}
			onCancel={handleClose}
			width={width}
		>
			{formik.values === null ? (
				<div className="modal__component-empty">
					<span>{t("dataNotFound")}</span>
				</div>
			) : (
				<>
					{pending || formLoading ? (
						<div className="modal__component-loader">
							<Loader />
						</div>
					) : (
						<>
							<div className="modal__component-header">
								{CustomTitle ? (
									<CustomTitle />
								) : (
									<span className="title">{title}</span>
								)}
								<div className="modal__component-header-iconsWrapper">
									{onViewToggle && (
										<span className="toggle_icon" onClick={onViewToggle}>
											{preview ? (
												<PreviewRowIcon color={theme.primary} />
											) : (
												<EditRowIcon stroke={theme.primary} />
											)}
										</span>
									)}
									{duplicate && (
										<span className="duplicate_icon" onClick={onDuplicateClick}>
											<DuplicateIcon />
										</span>
									)}
									<span className="close_icon" onClick={handleClose}>
										<ModalCloseIcon />
									</span>
								</div>
							</div>
							<div className="modal__component-content">
								<form className="form__wrapper" onSubmit={handleSubmit}>
									{previewInputs && (
										<div className="preview-inputs__wrapper">
											{previewInputs?.map((item, index) => (
												<PreviewInput
													key={item.field}
													item={item}
													values={formik.values}
													isLast={index === previewInputs.length - 1}
												/>
											))}
										</div>
									)}
									<div
										className={`form__wrapper-inputs ${inputsWrapperClassName}`}
									>
										{inputs?.map((input, index) => (
											<React.Fragment key={`${input.field}--${index}`}>
												{renderFormInputs(
													input,
													formik,
													inputs,
													setDatesModal,
													innerModal,
													renderInnerModal,
													datesModal,
													toggleInfoVisibility,
													modal,
													id,
												)}
												{fieldInfo?.[input.field] && (
													<CardInfo
														content={input.additionalInfo}
														color="#979797"
													/>
												)}
											</React.Fragment>
										))}
									</div>
									{canSave && (
										<div className="form__wrapper-actions">
											<ButtonComponent
												type="submit"
												disabled={loading}
												buttonText={title}
												loading={loading}
											/>
										</div>
									)}
								</form>
							</div>
						</>
					)}
				</>
			)}
		</Modal>
	);
};
export default Form;
